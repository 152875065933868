import dayjs, { Dayjs } from 'dayjs'

export interface ShiftManagement {
  id: string
  companyBranchId: string
  fromDate: Dayjs
  toDate: Dayjs
  scheduledRequestDate: Dayjs
  deadlineDate: number
  selectedShifts: number[]
  responses: SecurityShiftResponse[]
}

export interface SecurityShiftResponse {
  id: string
  securityShiftManagementId: string
  date: Dayjs
  schedule: number
  createdAt: string
  updatedAt: string
}

/**
 * deadlineDateの日数から、締め切り日を計算する
 */
export function calcDeadlineDate(shiftManagement: ShiftManagement): Dayjs {
  return dayjs(shiftManagement.fromDate).subtract(
    shiftManagement.deadlineDate,
    'day',
  )
}
