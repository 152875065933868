import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Typography,
  Grid,
  Divider,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import KumocanButton from 'components/button'
import KumocanDialog from 'components/dialog'
import { notificationApi } from 'hooks/api/notification'
import { PreferredTeamSize, preferredWorksiteOptions } from 'types/security'

export default function InterviewsPage() {
  const { id } = useParams<{ id: string }>()
  const [preferredTeamSize, setPreferredTeamSize] = useState<number>(0)
  const [preferredSites, setPreferredSites] = useState<number[]>([])
  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    if (id) {
      notificationApi().readed(id)
      notificationApi()
        .findInteview(id)
        .then((res) => {
          if (res.data.preferredTeamSizes.length) {
            setPreferredTeamSize(res.data.preferredTeamSizes[0])
          }
          if (res.data.preferredWorkSites.length) {
            setPreferredSites(res.data.preferredWorkSites)
          }
        })
    }
  }, [id])

  const saveValue = async () => {
    return await notificationApi().updateInteview(
      id!,
      [preferredTeamSize],
      preferredSites,
    )
  }

  const handleTeamSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPreferredTeamSize(Number(event.target.value))
    setTimeout(() => {
      saveValue()
    }, 500)
  }

  const handleSiteToggle = (site: number) => {
    setPreferredSites((prev) =>
      prev.includes(site) ? prev.filter((s) => s !== site) : [...prev, site],
    )
    setTimeout(() => {
      saveValue()
    }, 500)
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    saveValue().then(() => {
      setOpen(true)
    })
  }

  const toTop = () => {
    location.href = '/notification'
  }

  const disabled = !preferredTeamSize && preferredSites.length === 0

  return (
    <Box sx={{ p: 3, textAlign: 'center' }}>
      <Typography variant="h2" gutterBottom>
        アンケート
      </Typography>
      <Box sx={{ backgroundColor: '#ffa895', padding: '10px' }}>
        アンケートの内容は、会社と私たちで参考にさせていただきますが、すべてのご希望に沿えるわけではないことをご了承ください
      </Box>
      <Paper sx={{ p: 2, mt: 1 }}>
        <form onSubmit={handleSubmit}>
          {/* 質問1: ラジオボタン */}
          <FormControl component="fieldset" sx={{ mb: 3 }}>
            <Typography component="strong" sx={{ fontSize: '1.2rem' }}>
              どんな人数だと働きやすいですか?
              <br />
              (一つ選択)
            </Typography>
            <RadioGroup
              name="teamSize"
              value={preferredTeamSize}
              onChange={handleTeamSizeChange}
            >
              <FormControlLabel
                value={PreferredTeamSize.OnePerson}
                control={
                  <Radio sx={{ '& .MuiSvgIcon-root': { fontSize: '2rem' } }} />
                }
                label={<Typography sx={{ fontSize: '1.4rem' }}>1人</Typography>}
              />
              <FormControlLabel
                value={PreferredTeamSize.TwoToFour}
                control={
                  <Radio sx={{ '& .MuiSvgIcon-root': { fontSize: '2rem' } }} />
                }
                label={
                  <Typography sx={{ fontSize: '1.4rem' }}>2~4人</Typography>
                }
              />
              <FormControlLabel
                value={PreferredTeamSize.FiveOrMore}
                control={
                  <Radio sx={{ '& .MuiSvgIcon-root': { fontSize: '2rem' } }} />
                }
                label={
                  <Typography sx={{ fontSize: '1.4rem' }}>5人以上</Typography>
                }
              />
              <FormControlLabel
                value={PreferredTeamSize.DontCare}
                control={
                  <Radio sx={{ '& .MuiSvgIcon-root': { fontSize: '2rem' } }} />
                }
                label={
                  <Typography sx={{ fontSize: '1.4rem' }}>
                    気にしない
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>
          {/* 質問2 */}
          <Divider />
          <FormControl component="fieldset" sx={{ marginTop: '20px' }}>
            <Typography component="legend" sx={{ fontSize: '1.2rem' }}>
              自分に向いてる現場はありますか?
              <br />
              (複数選択可)
            </Typography>
            <Grid container spacing={2} textAlign="left" marginTop={'7px'}>
              {preferredWorksiteOptions.map((option) => (
                <Grid item xs={12} key={option.value} marginLeft="10px">
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="large"
                        checked={preferredSites.includes(Number(option.value))}
                        onChange={() => handleSiteToggle(Number(option.value))}
                      />
                    }
                    label={
                      <Typography sx={{ fontSize: '1.4rem' }}>
                        {option.label}
                      </Typography>
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </FormControl>
          <Button
            disabled={disabled}
            type="submit"
            variant="contained"
            color="primary"
            sx={{ fontSize: '1.3rem', width: '200px', marginTop: '30px' }}
          >
            送信する
          </Button>
        </form>
      </Paper>
      <KumocanDialog open={open}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography fontSize={'18px'} textAlign={'center'}>
            アンケート回答
            <br />
            ありがとうございました
          </Typography>
          <KumocanButton
            onClick={toTop}
            style={{ margin: '20px auto 0', width: '120px', fontSize: '18px' }}
          >
            一覧へ戻る
          </KumocanButton>
        </Box>
      </KumocanDialog>
    </Box>
  )
}
