import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { Box, Typography } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionActions from '@mui/material/AccordionActions'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import dayjs, { Dayjs } from 'dayjs'
import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import { useForm, useFieldArray } from 'react-hook-form'

import KumocanButton from 'components/button'
import KumocanDialog from 'components/dialog'
import KumocanSelectBox, { Option } from 'components/selectBox'
import { shiftApi } from 'hooks/api/shift'
import { isFutureDayjs, NewDate } from 'hooks/helper/date'
import { ShiftManagement } from 'types/shiftManagement'

export const SCHEDULE = {
  DAY_OFF: 1,
  DAY: 2,
  NIGHT: 3,
  CONTINUOUS: 4,
  REVIEW: 5,
  DAY_OR_NIGHT: 6,
  TRAINING: 7,
  AM: 8,
  PM: 9,
}

export const SHIFT_OPTIONS: Option[] = [
  {
    label: '日勤',
    value: SCHEDULE.DAY,
  },
  {
    label: '夜勤',
    value: SCHEDULE.NIGHT,
  },
  {
    label: '日夜可',
    value: SCHEDULE.CONTINUOUS,
  },
  {
    label: '日or夜',
    value: SCHEDULE.DAY_OR_NIGHT,
  },
  {
    label: '休み',
    value: SCHEDULE.DAY_OFF,
  },
  {
    label: '午前のみ',
    value: SCHEDULE.AM,
  },
  {
    label: '午後のみ',
    value: SCHEDULE.PM,
  },
]

export type SubmitShiftProps = {
  shiftManagement: ShiftManagement
}

interface Shift {
  date: Dayjs
  schedule: number
}

interface Form {
  shifts: Shift[]
}

const SubmitShift: React.FC<SubmitShiftProps> = ({ shiftManagement }) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [isCompleteDialogOpen, setIsCompleteDialogOpen] =
    useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { control, handleSubmit } = useForm<Form>()
  const { fields, append, update } = useFieldArray({
    control,
    name: 'shifts',
  })
  const [allowedShiftOptions, setAllowedShiftOptions] = useState<Option[]>([])

  // 曜日の取得（日本語）
  const getJapaneseWeekday = (date: Dayjs) => {
    const weekdays = ['日', '月', '火', '水', '木', '金', '土']
    return weekdays[date.day()]
  }

  const submitShiftRange = (): string => {
    const fromDate = shiftManagement.fromDate
    const toDate = shiftManagement.toDate

    return `${fromDate.month() + 1}/${fromDate.date()}(${getJapaneseWeekday(
      fromDate,
    )}) ~ ${toDate.month() + 1}/${toDate.date()}(${getJapaneseWeekday(toDate)})`
  }

  const getDates = (): Dayjs[] => {
    const dates: Dayjs[] = []
    // shiftManagement.fromDate と shiftManagement.toDate は適切な値が入っている前提
    let fromDate = shiftManagement.fromDate.startOf('day')
    const toDate = shiftManagement.toDate.startOf('day')

    // 開始日から終了日まで1日ずつ追加
    while (fromDate.isBefore(toDate) || fromDate.isSame(toDate)) {
      dates.push(fromDate)
      fromDate = fromDate.add(1, 'day')
    }
    return dates
  }

  const onSelectShift = (index: number, date: Dayjs, schedule: number) => {
    if (index >= 0) {
      update(index, { date, schedule })
      shiftApi().save(shiftManagement.id, NewDate(date), schedule)
    }
  }

  const handleOpen = () => {
    setIsDialogOpen(true)
  }

  const handleClose = () => {
    setIsDialogOpen(false)
    setIsCompleteDialogOpen(false)
  }

  const onSubmit = async (data: Form) => {
    setIsDialogOpen(false)
    const values = data.shifts.map((shift) => ({
      date: dayjs(shift.date),
      schedule: shift.schedule,
    }))
    shiftApi()
      .submit(shiftManagement.id, values)
      .then(() => {
        setIsCompleteDialogOpen(true)
        setIsSubmitting(true)
      })
  }

  const disabledButton = (shifts: Shift[]): boolean => {
    return shifts.some((shift) => shift.schedule === 0)
  }

  const isInitialized = useRef(false)

  useEffect(() => {
    // 既に初期化済みならスキップ
    if (isInitialized.current) return
    isInitialized.current = true

    const dates = getDates()
    const initialShifts = dates.map((date) => {
      const foundResponse = shiftManagement.responses.find((response) =>
        response.date.isSame(date, 'day'),
      )
      return { date, schedule: foundResponse ? foundResponse.schedule : 0 }
    })
    if (
      !shiftManagement.selectedShifts ||
      shiftManagement.selectedShifts.length === 0
    )
      return

    const filteredShiftOptions = SHIFT_OPTIONS.filter((option) =>
      shiftManagement.selectedShifts.includes(option.value as number),
    )
    setAllowedShiftOptions(filteredShiftOptions)

    append(initialShifts)
  }, [append, shiftManagement])

  return (
    <Accordion style={containerStyle}>
      <AccordionSummary style={headerStyle}>
        {submitShiftRange()}
      </AccordionSummary>
      <AccordionDetails>
        <form>
          <ul style={dateListStyle}>
            {fields.map((field, index) => (
              <li key={index} style={dateItemStyle}>
                <div style={dayLeftStyle}>
                  <div style={dayStyle}>
                    <p style={dayNumberStyle}>{field.date.date()}</p>
                    <p style={weekdayStyle}>
                      ({getJapaneseWeekday(field.date)})
                    </p>
                  </div>
                </div>
                <div style={dayRightStyle}>
                  <KumocanSelectBox
                    name={`shifts.${index}.schedule`}
                    control={control}
                    style={selectStyle(field.schedule)}
                    options={allowedShiftOptions}
                    required={isFutureDayjs(field.date)}
                    onChange={(e) =>
                      onSelectShift(index, field.date, e.target.value)
                    }
                    disabled={isSubmitting}
                  ></KumocanSelectBox>
                </div>
              </li>
            ))}
          </ul>
        </form>
      </AccordionDetails>

      <AccordionActions>
        <KumocanButton style={backButtonStyle} color={'inherit'}>
          <KeyboardArrowLeftIcon style={backButtonIconStyle} />
          戻る
        </KumocanButton>
        <KumocanButton
          buttonType={'submit'}
          style={submitButtonStyle}
          disabled={disabledButton(fields) || isSubmitting}
          onClick={handleSubmit(handleOpen)}
        >
          <ForwardToInboxIcon style={submitButtonIconStyle} />
          シフト提出
        </KumocanButton>
      </AccordionActions>

      <KumocanDialog open={isDialogOpen} handleClose={handleClose}>
        <Box>
          <Typography
            sx={{
              fontSize: '20px',
              fontWeight: 'bold',
            }}
          >
            シフトを提出してよろしいですか？一度提出したシフトの修正はアプリ上では行えません。
          </Typography>
          <Box sx={dialogActionStyle}>
            <KumocanButton
              onClick={handleClose}
              variant="contained"
              color="inherit"
              style={dialogButtonStyle}
            >
              キャンセル
            </KumocanButton>
            <KumocanButton
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              style={dialogButtonStyle}
              disabled={disabledButton(fields) || isSubmitting}
            >
              提出する
            </KumocanButton>
          </Box>
        </Box>
      </KumocanDialog>
      <KumocanDialog open={isCompleteDialogOpen} handleClose={handleClose}>
        <Box>
          <Typography
            sx={{
              fontSize: '20px',
              fontWeight: 'bold',
            }}
          >
            シフトを提出しました
          </Typography>
        </Box>
      </KumocanDialog>
    </Accordion>
  )
}

export default SubmitShift

const containerStyle: CSSProperties = {
  width: '100%',
  maxWidth: '400px',
  overflow: 'hidden',
  boxShadow: 'none',
  margin: '0',
}

const headerStyle: CSSProperties = {
  padding: '12px',
  backgroundColor: 'white',
  textAlign: 'center',
  fontSize: '18px',
  fontWeight: 'bold',
}

const dateListStyle: CSSProperties = {
  listStyle: 'none',
  margin: 0,
  padding: 0,
  maxHeight: '500px',
  overflowY: 'scroll',
  overflowX: 'hidden',
}

const dateItemStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  height: '80px',
}

const dayLeftStyle: CSSProperties = {
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '10%',
  height: '100%',
  textAlign: 'center',
  backgroundColor: '#f3f4f6',
}

const dayStyle: CSSProperties = {
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#f3f4f6',
  color: 'black',
}

const dayNumberStyle: CSSProperties = {
  fontSize: '12px',
  fontWeight: 'bold',
}

const weekdayStyle: CSSProperties = {
  fontSize: '8px',
}

const dayRightStyle: CSSProperties = {
  height: 'calc(100% - 8px)',
  width: 'calc(90% - 16px)',
  backgroundColor: 'white',
  padding: '4px 8px',
}

const shiftColor = (schedule: number): string => {
  switch (schedule) {
    case SCHEDULE.DAY_OFF:
      return 'rgb(175, 182, 182)'
    case SCHEDULE.DAY:
      return 'rgba(255, 72, 0, 0.5)'
    case SCHEDULE.NIGHT:
      return 'rgb(128, 176, 197)'
    case SCHEDULE.CONTINUOUS:
      return 'rgb(223, 173, 104)'
    case SCHEDULE.DAY_OR_NIGHT:
      return 'rgb(137, 211, 188)'
    case SCHEDULE.AM:
      return '#FFC5B9'
    case SCHEDULE.PM:
      return '#FFA896'
    default:
      return 'white'
  }
}

const selectStyle = (schedule: number): CSSProperties => {
  const color = shiftColor(schedule)

  return {
    minWidth: '100%',
    maxWidth: 'inherit',
    height: '100%',
    display: 'inline-block',
    fontSize: '22px',
    fontWeight: 'bold',
    lineHeight: '65px',
    backgroundColor: color,
  }
}

const backButtonStyle: CSSProperties = {
  position: 'relative',
  width: '100%',
  height: '60px',
  fontSize: '22px',
  fontWeight: 'bold',
  color: 'gray',
  borderRadius: '10px',
  backgroundColor: 'white',
  border: '2px solid #e5e7eb',
  boxShadow: 'none',
}

const backButtonIconStyle: CSSProperties = {
  position: 'absolute',
  left: '5%',
  fontSize: '34px',
}

const submitButtonIconStyle: CSSProperties = {
  fontSize: '28px',
}

const submitButtonStyle: CSSProperties = {
  width: '100%',
  height: '60px',
  fontSize: '20px',
  fontWeight: 'bold',
  borderRadius: '10px',
  boxShadow: 'none',
  justifyContent: 'space-around',
}

const dialogActionStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  gap: '10px',
  marginTop: '20px',
}

const dialogButtonStyle: CSSProperties = {
  width: '100%',
  fontSize: '18px',
  borderRadius: '4px',
  boxShadow: 'none',
  color: 'white',
  height: '50px',
}
