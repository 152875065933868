import { Client } from './client'
import { ContractDetail } from './contract'
import { Notification } from './notification'
import { OrderWithContractDetail } from './order'
import { Security } from './security'

export interface Assign {
  id: string
  orderId: string
  status: number
  replyWhenUnavailable: string
  replyWhenAvailable: string
  suspensionConfirm: number
  note: string
  securityNote: string
  security: Security
  hasQualified: boolean
}

export interface AllianceAssign {
  id: string
  orderId: string
  clientId: string
  status: number
  client: Client
  hasQualified: boolean
}

export interface AssignMember {
  assignId: string
  security: Security
}

export interface AssignWithOrder {
  id: string
  status: number
  replyWhenUnavailable: string
  replyWhenAvailable: string
  note: string
  securityNote: string
  notification?: Notification
  order: OrderWithContractDetail
  members: AssignMember[]
}

export interface AssignWithContract {
  id: string
  orderId: string
  date: string
  status: number
  address: string
  lat: number
  lng: number
  contractDetail: ContractDetail
  securityNote: string
  isLeader: boolean
}

export const ASSIGN_STATUS = {
  COMPLETED: 0, // 配置済み
  DELETED: 10, // 削除済み
  NOTIFIED: 20, // 通知済み
  AVAILABLE_FOR_WORK: 21, // 出勤可能
  UNAVAILABLE_FOR_WORK: 25, // 出勤不可能
  ATTENDANCE_REPORT: 30, // 出発報告
  ATTENDANCE_UP: 40, // 上番
  ATTENDANCE_DOWN: 50, // 下番
}

export const SECURITY_REVIEW = {
  CONSIDERED_QUITTING: 10, // v2: 退職を考えるほど嫌な勤務だった
  UNPLEASANT: 15, // v2: 嫌な勤務だった
  HAD_BETTER_BEFORE: 20, // v2: 過去にもっと良い勤務があった
  WILLING_TO_CONTINUE: 25, // v2: この勤務を続けたい
}
