import './page.scss'
import 'dayjs/locale/ja'
import {
  Alert,
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'
import { Dayjs } from 'dayjs'
import {
  CSSProperties,
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useParams } from 'react-router-dom'

import KumocanButton from 'components/button'
import KumocanDialog from 'components/dialog'
import { notificationApi } from 'hooks/api/notification'
import { convertToLink } from 'hooks/helper/link'
import { AllianceAssign, Assign, ASSIGN_STATUS } from 'types/assign'
import { Notification, notificationStatus } from 'types/notification'
import { OrderWithAssign } from 'types/order'

// 日付を「YYYY年MM月DD日(曜日)」形式で表示する関数
const formatDateWithDay = (date: Dayjs) => {
  return date.format('YYYY年MM月DD日(ddd)') // 「YYYY年MM月DD日(土)」のように表示
}

const cardStyle: CSSProperties = {
  width: '90%',
  margin: '20px auto',
  padding: '20px',
  borderRadius: '10px',
}

const submitButtonStyle: CSSProperties = {
  width: '100%',
  height: '50px',
  fontSize: '18px',
  margin: '20px auto 0',
  borderRadius: '10px',
}

// NOTE 配置の返答ページ
export default function AssignConfirmPage() {
  const [totalVolume, setTotalVolume] = useState<number>()
  const [qualfiedVolume, setQualifiedVolume] = useState<number>()
  const [nonQualfiedVolume, setNonQualifiedVolume] = useState<number>()
  const [order, setOrder] = useState<OrderWithAssign>()
  const [replyFomat, setReplyFomat] = useState<string>()
  const [res, setRes] = useState<number>(ASSIGN_STATUS.AVAILABLE_FOR_WORK)
  const [replyWhenUnavailable, setReplyWhenUnavailable] = useState<string>('')
  const [replyWhenAvailable, setReplyWhenAvailable] = useState<string>('')
  const [otherAssigns, setOtherAssigns] = useState<Assign[]>([])
  const [otherAllianceAssigns, setOtherAllianceAssigns] = useState<
    AllianceAssign[]
  >([])
  const [open, setOpen] = useState<boolean>(false)
  const [notification, setNotification] = useState<Notification>()
  const [replyWhenUnavailableErr, setReplyWhenUnavailableErr] =
    useState<string>('')

  const { id } = useParams<{ id: string }>()
  const fetchData = useCallback(() => {
    notificationApi()
      .findById(id!)
      .then((res) => {
        setNotification(res.data)
        if (res.data.status === notificationStatus.Notified) {
          notificationApi().readed(id!)
        }
      })
    notificationApi()
      .findAssignById(id!)
      .then((res) => {
        setTotalVolume(res.data.volume)
        setQualifiedVolume(res.data.qualifiedVolume)
        setNonQualifiedVolume(res.data.nonQualifiedVolume)
        setOrder(res.data.order)
        setReplyFomat(res.data.replyFomat)
        setReplyWhenAvailable(res.data.replyFomat)
        setOtherAssigns(res.data.otherAssigns)
        setOtherAllianceAssigns(res.data.otherAllianceAssigns)
      })
      .catch((err) => {
        console.error('Error fetching data:', err)
      })
  }, [id])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const isDisabled = (): boolean => {
    if (res === 0) return true
    if (order?.assign.status !== ASSIGN_STATUS.NOTIFIED) return true
    return false
  }

  const isCancel = () => {
    return (
      order?.assign.id === '' ||
      order?.assign.status === ASSIGN_STATUS.COMPLETED ||
      !order
    )
  }

  const statusLabel = () => {
    // 配置が紐づいてない,または配置が配置済みに戻された時はキャンセルされたものとして扱う
    if (isCancel()) {
      return (
        <Alert severity="warning">この勤務指示はキャンセルされました</Alert>
      )
    }
    switch (order?.assign.status) {
      case ASSIGN_STATUS.NOTIFIED:
        return (
          <Alert severity="info">
            以下の案件に割り当てられました。確認をお願い致します
          </Alert>
        )
      case ASSIGN_STATUS.DELETED:
        return (
          <Alert severity="warning">
            以下の案件への割り当てはキャンセルされました
          </Alert>
        )
      case ASSIGN_STATUS.AVAILABLE_FOR_WORK:
        return (
          <Alert severity="success">
            以下内容で回答しました
            <br />
            <p>回答: 確認しました</p>
            <p>返信コメント: {order.assign.replyWhenAvailable}</p>
          </Alert>
        )
      case ASSIGN_STATUS.UNAVAILABLE_FOR_WORK:
        return (
          <Alert severity="warning">
            以下内容で回答しました
            <br />
            <p>回答: 報告</p>
            <p>理由: {order.assign.replyWhenUnavailable}</p>
          </Alert>
        )
    }
  }

  const handleResChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReplyWhenUnavailable('')
    setReplyWhenAvailable('')
    setRes(Number((event.target as HTMLInputElement).value))
  }

  const handleReplyWhenUnavailable = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const data = event.target.value.trim()
    setReplyWhenUnavailable(data)
  }

  const handleReplyChange = (event: ChangeEvent<HTMLInputElement>) => {
    const data = event.target.value.trim()
    setReplyWhenAvailable(data)
  }

  const handleOepn = () => {
    if (res === ASSIGN_STATUS.UNAVAILABLE_FOR_WORK && !replyWhenUnavailable) {
      setReplyWhenUnavailableErr('報告内容を教えてください🙇')
      return
    }
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const submit = () => {
    notificationApi()
      .replyAssignPossibility(
        id!,
        res,
        replyWhenAvailable,
        replyWhenUnavailable,
      )
      .then(() => {
        handleClose()
        fetchData()
      })
  }

  const toMap = () => {
    if (order?.lat !== 0 && order?.lng !== 0) {
      // 緯度経度が存在する場合、緯度経度でGoogleマップを開く
      location.href = `https://www.google.com/maps/search/?api=1&query=${order?.lat},${order?.lng}&launch_app=true`
    } else if (order?.address) {
      // 緯度経度が0の場合は住所でGoogleマップを開く
      location.href = `https://www.google.com/maps/search/?api=1&query=${order?.address}&launch_app=true`
    }
  }

  const {
    qualifiedAssigns,
    nonQualifiedAssigns,
    qualifiedAllianceAssigns,
    nonQualifiedAllianceAssigns,
    qualifiedPendingCount,
    nonQualifiedPendingCount,
  } = useMemo(() => {
    const workingStatuses = [
      ASSIGN_STATUS.AVAILABLE_FOR_WORK,
      ASSIGN_STATUS.ATTENDANCE_REPORT,
      ASSIGN_STATUS.ATTENDANCE_UP,
      ASSIGN_STATUS.ATTENDANCE_DOWN,
    ]

    const qualifiedAssigns = otherAssigns.filter(
      (a) => workingStatuses.includes(a.status) && !!a.hasQualified,
    )
    const nonQualifiedAssigns = otherAssigns.filter(
      (a) => workingStatuses.includes(a.status) && !a.hasQualified,
    )

    const qualifiedAllianceAssigns = otherAllianceAssigns.filter(
      (a) => !!a.hasQualified,
    )
    const nonQualifiedAllianceAssigns = otherAllianceAssigns.filter(
      (a) => !a.hasQualified,
    )

    // 配置予定人数に対して、まだ未確定(足りていない人数)を計算して「確認中」を表示するための数
    const qualifiedPendingCount = Math.max(
      (qualfiedVolume || 0) -
        qualifiedAssigns.length -
        qualifiedAllianceAssigns.length,
      0,
    )
    const nonQualifiedPendingCount = Math.max(
      (nonQualfiedVolume || 0) -
        nonQualifiedAssigns.length -
        nonQualifiedAllianceAssigns.length,
      0,
    )

    return {
      qualifiedAssigns: qualifiedAssigns,
      nonQualifiedAssigns: nonQualifiedAssigns,
      qualifiedAllianceAssigns: qualifiedAllianceAssigns,
      nonQualifiedAllianceAssigns: nonQualifiedAllianceAssigns,
      qualifiedPendingCount: qualifiedPendingCount,
      nonQualifiedPendingCount: nonQualifiedPendingCount,
    }
  }, [otherAssigns, otherAllianceAssigns, qualfiedVolume, nonQualfiedVolume])

  // 配置済みの時のみ回答できるように
  const showResponseContainer: boolean =
    order?.assign.status === ASSIGN_STATUS.NOTIFIED

  return (
    <Box sx={{ paddingBottom: '300px' }}>
      <Box>
        <Typography
          component={'div'}
          sx={{
            display: 'block',
            width: '85%',
            margin: '20px auto',
            fontSize: '16px',
            color: '#333',
            lineHeight: '1.6',
          }}
        >
          {statusLabel()}
        </Typography>
        <Box sx={cardStyle}>
          {!isCancel() && order ? (
            <>
              <div
                style={{
                  textAlign: 'left',
                  marginBottom: '10px',
                  borderBottom: '1px dotted #ccc',
                  paddingBottom: '10px',
                }}
              >
                <p style={{ fontWeight: 'bold' }}>
                  {formatDateWithDay(order.date!)}{' '}
                  {order.startTime?.format('HH:mm')}~
                  {order.endTime?.format('HH:mm')}
                </p>
              </div>

              <div style={columnDivStyle}>
                <p style={{ fontWeight: 'bold', fontSize: `12px` }}>契約先名</p>
                <p>{order.clientName}</p>
              </div>

              <div style={columnDivStyle}>
                <p style={{ fontWeight: 'bold', fontSize: `12px` }}>案件名</p>
                <p>{order.contractDetailName}</p>
              </div>

              <div style={columnDivStyle}>
                <p style={{ fontWeight: 'bold', fontSize: `12px` }}>最寄駅</p>
                <p>{order.nearestStation}</p>
              </div>

              <div style={columnDivStyle}>
                <p style={{ fontWeight: 'bold', fontSize: `12px` }}>
                  現場住所:
                </p>
                <div>
                  <p>{order.address}</p>
                  <div style={{ marginTop: '10px', textAlign: 'left' }}>
                    {' '}
                    <KumocanButton onClick={toMap}>地図を表示</KumocanButton>
                  </div>
                </div>
              </div>
              <div style={columnDivStyle}>
                <p style={{ fontWeight: 'bold', fontSize: `12px` }}>配置人数</p>
                <p>{totalVolume} 人</p>
              </div>
              <div style={columnDivStyle}>
                <p style={{ fontWeight: 'bold', fontSize: '12px' }}>勤務者</p>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                  }}
                >
                  <div>
                    <p style={hasQualifiedStyle}>資格無</p>
                    <div style={arrayStyle}>
                      {nonQualifiedAssigns.map((a) => (
                        <div key={a.security.id} style={badgeStyle}>
                          {a.security.lastName} {a.security.firstName}
                        </div>
                      ))}
                      {Array.from({ length: nonQualifiedPendingCount }).map(
                        (_, i) => (
                          <div key={`nonq-pending-${i}`} style={pendingStyle}>
                            調整中
                          </div>
                        ),
                      )}
                    </div>
                    {nonQualifiedAllianceAssigns.length > 0 && (
                      <div style={{ marginTop: '10px' }}>
                        <p style={allianceTextStyle}>協力会社</p>
                        <div style={arrayStyle}>
                          {nonQualifiedAllianceAssigns.map((a) => (
                            <div key={a.clientId} style={allianceBadgeStyle}>
                              {a.client.displayName}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>

                  <div>
                    <p style={hasQualifiedStyle}>資格有</p>
                    <div style={arrayStyle}>
                      {qualifiedAssigns.map((a) => (
                        <div key={a.security.id} style={badgeStyle}>
                          {a.security.lastName} {a.security.firstName}
                        </div>
                      ))}
                      {Array.from({ length: qualifiedPendingCount }).map(
                        (_, i) => (
                          <div key={`nonq-pending-${i}`} style={pendingStyle}>
                            調整中
                          </div>
                        ),
                      )}
                    </div>
                    {qualifiedAllianceAssigns.length > 0 && (
                      <div style={{ marginTop: '10px' }}>
                        <p style={allianceTextStyle}>協力会社</p>
                        <div style={arrayStyle}>
                          {qualifiedAllianceAssigns.map((a) => (
                            <div key={a.clientId} style={allianceBadgeStyle}>
                              {a.client.displayName}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div style={columnDivStyle}>
                <p
                  style={{
                    fontWeight: 'bold',
                    fontSize: '12px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  管制室からのコメント
                </p>
                <Typography
                  sx={{
                    whiteSpace: 'pre-wrap',
                    marginTop: '25px',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: convertToLink(notification?.content || ''),
                  }}
                ></Typography>
              </div>
            </>
          ) : (
            <></>
          )}

          {showResponseContainer && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '20px',
              }}
            >
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={res}
                  onChange={handleResChange}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    gap: '20px',
                    marginBottom: '10px',
                  }}
                >
                  <FormControlLabel
                    value={ASSIGN_STATUS.AVAILABLE_FOR_WORK}
                    control={
                      <Radio
                        sx={{
                          color: '#cccccc',
                          '&.Mui-checked': {
                            color: '#14b7ae',
                          },
                        }}
                      />
                    }
                    label="確認しました"
                    sx={{ fontSize: '16px' }}
                  />

                  <FormControlLabel
                    value={ASSIGN_STATUS.UNAVAILABLE_FOR_WORK}
                    control={
                      <Radio
                        sx={{
                          color: '#cccccc',
                          '&.Mui-checked': {
                            color: '#14b7ae',
                          },
                        }}
                      />
                    }
                    label="報告"
                    sx={{ fontSize: '16px' }}
                  />
                </RadioGroup>

                {res === ASSIGN_STATUS.UNAVAILABLE_FOR_WORK && (
                  <TextField
                    sx={{ marginTop: '10px', width: '85vw' }}
                    id="outlined-multiline-flexible"
                    label="報告内容"
                    multiline
                    maxRows={5}
                    required
                    onChange={handleReplyWhenUnavailable}
                    error={!!replyWhenUnavailableErr.trim()}
                  />
                )}

                {res === ASSIGN_STATUS.AVAILABLE_FOR_WORK && (
                  <TextField
                    label="返信コメント"
                    sx={{ marginTop: '10px', width: '85vw' }}
                    id="outlined-multiline-flexible"
                    multiline
                    defaultValue={replyFomat}
                    maxRows={5}
                    onChange={handleReplyChange}
                  />
                )}

                {replyWhenUnavailableErr && (
                  <Typography sx={{ color: 'red', marginTop: '10px' }}>
                    {replyWhenUnavailableErr}
                  </Typography>
                )}

                <KumocanButton
                  onClick={handleOepn}
                  style={submitButtonStyle}
                  disabled={isDisabled()}
                >
                  回答する
                </KumocanButton>
              </FormControl>
            </Box>
          )}
        </Box>
      </Box>
      <KumocanDialog open={open} handleClose={handleClose}>
        <Typography sx={{ marginBottom: '10px' }}>
          以下内容で回答しますか？
        </Typography>
        {res === ASSIGN_STATUS.AVAILABLE_FOR_WORK && (
          <table className="reply-table">
            <tbody>
              <tr>
                <td>回答:</td>
                <td>
                  <strong>確認しました</strong>
                </td>
              </tr>
              <tr>
                <td>返信コメント:</td>
                <td>{replyWhenAvailable}</td>
              </tr>
            </tbody>
          </table>
        )}
        {res === ASSIGN_STATUS.UNAVAILABLE_FOR_WORK && (
          <table className="reply-table">
            <tbody>
              <tr>
                <td>回答:</td>
                <td>
                  <strong style={{ color: 'red' }}>報告</strong>
                </td>
              </tr>
              <tr>
                <td>理由:</td>
                <td>{replyWhenUnavailable}</td>
              </tr>
            </tbody>
          </table>
        )}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '20px',
          }}
        >
          <KumocanButton onClick={handleClose} variant="outlined">
            いいえ
          </KumocanButton>
          <KumocanButton onClick={submit} style={{ width: '60px' }}>
            はい
          </KumocanButton>
        </Box>
      </KumocanDialog>
    </Box>
  )
}

const columnDivStyle = {
  display: 'grid',
  gridTemplateColumns: '30% 70%',
  gap: '10px',
  marginBottom: '10px',
  borderBottom: '1px dotted #ccc',
  paddingBottom: '10px',
}

const hasQualifiedStyle = {
  fontSize: '10px',
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
}

const allianceTextStyle = {
  fontSize: '8px',
  color: 'gray',
  whiteSpace: 'nowrap',
}

const arrayStyle: CSSProperties = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '5px',
  marginBottom: '5px',
}

const badgeStyle = {
  backgroundColor: '#f5f5f5',
  padding: '5px 10px',
  borderRadius: '5px',
  fontSize: '12px',
}

const allianceBadgeStyle = {
  padding: '5px 10px',
  borderRadius: '5px',
  fontSize: '12px',
  border: '0.5px solid #ccc',
}

const pendingStyle = {
  ...badgeStyle,
  backgroundColor: '#eee',
  color: '#aaa',
}
