import { AxiosResponse } from 'axios'

import { AddAuthSession, _axios } from 'hooks/axios'
import { AssignWithContract } from 'types/assign'

const AUTH = `/auth`
const SIGNIN = `/signin`
const FILTER_ASSIGN_BY_DATE = `/securities/assign`

export const securityApi = () => {
  const filterAssignByDate = async (
    date: string,
  ): Promise<AxiosResponse<AssignWithContract>> => {
    return await _axios()
      .get(FILTER_ASSIGN_BY_DATE, {
        params: {
          date: date,
        },
      })
      .then((res) => {
        res.data = res.data
        return res
      })
  }

  const authKumocan = async (
    pass: string,
  ): Promise<AxiosResponse<AssignWithContract>> => {
    return await _axios()
      .post(AUTH, { password: pass })
      .then((res) => {
        res.data = res.data
        return res
      })
  }

  const signIn = async (
    id: string,
    pass: string,
  ): Promise<AxiosResponse<AssignWithContract>> => {
    return await _axios()
      .post(SIGNIN, {
        loginID: id,
        password: pass,
      })
      .then((res) => {
        res.data = res.data
        AddAuthSession(res.data.accessToken)
        return res
      })
  }

  return {
    filterAssignByDate,
    signIn,
    authKumocan,
  }
}
