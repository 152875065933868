import './reset.scss'
import { ThemeProvider } from '@emotion/react'
import { createTheme } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { locale } from 'dayjs'
import 'dayjs/locale/ja'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'

import NoSessionPage from 'error'
import { GetAuthSession } from 'hooks/axios'
import Layout from 'layout'
import AssignPage from 'pages/assign/page'
import LoginPage from 'pages/login/page'
import TopPage from 'pages/top/page'
import { notificationsRoutes } from 'routes/notification'
import { SecurityReportRoutes } from 'routes/security-report'
import { shiftsRoutes } from 'routes/shift'
import { PRIMARY_COLOR } from 'valiable'

// 日本語に
locale('ja')

import NotFoundPage from './notFound'

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR,
      light: PRIMARY_COLOR,
      dark: PRIMARY_COLOR,
      contrastText: '#ffffff',
    },
    secondary: {
      main: PRIMARY_COLOR,
    },
    warning: {
      main: '#ff4800',
    },
    error: {
      main: '#ff005a',
    },
    action: {
      hover: '#edebeb',
    },
  },
  typography: {
    fontSize: 12,
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          padding: '6px 8px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '6px 8px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '8px 8px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '0 0 10px',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          [`& .MuiOutlinedInput-root`]: {
            paddingTop: '0 !important',
            paddingBottom: '0 !important',
            paddingLeft: '0 !important',
          },
        },
        input: {
          minHeight: '30px',
        },
      },
    },
  },
})

const isAuthenticated = (): boolean => {
  const sessionKey = GetAuthSession()
  if (!!sessionKey) {
    return true
  } else {
    return false
  }
}

function App() {
  return (
    <Router>
      <ThemeProvider theme={defaultTheme}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
          <Routes>
            <Route path={'/login'} element={<LoginPage />} />
            <Route
              path="/"
              element={isAuthenticated() ? <Layout /> : <NoSessionPage />}
            >
              <Route path={'/top'} element={<TopPage />} />
              <Route path={'/assign'} element={<AssignPage />} />
              {notificationsRoutes.map((route, index) => (
                <Route key={index} path={route.path} element={route.element} />
              ))}
              {shiftsRoutes.map((route, index) => (
                <Route key={index} path={route.path} element={route.element} />
              ))}
              {SecurityReportRoutes.map((route, index) => (
                <Route key={index} path={route.path} element={route.element} />
              ))}
              <Route path="*" element={<NotFoundPage />} />
            </Route>
          </Routes>
        </LocalizationProvider>
      </ThemeProvider>
    </Router>
  )
}

export default App
