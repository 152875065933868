import dayjs, { Dayjs, extend } from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

export function NewDate(str?: string | Date | Dayjs | null): Dayjs {
  extend(utc)
  extend(timezone)
  const day = str ? dayjs(str) : dayjs(new Date())
  return day
}

// TODO 削除
export const isToday = (date: Date) => {
  const today = new Date()
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  )
}

// TODO 削除
export const isFuture = (date: Date) => {
  const today = new Date()
  return today < date || isToday(date)
}

export const isFutureDayjs = (date: Dayjs): boolean => {
  const today = dayjs().startOf('day')
  return !date.isBefore(today, 'day')
}
