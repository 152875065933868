import { Alert, Box, Divider, Grid, Typography } from '@mui/material'
import { Dayjs } from 'dayjs'
import { CSSProperties, useCallback, useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'

import KumocanButton from 'components/button'
import KumocanDialog from 'components/dialog'
import KumocanTextareaAutosize from 'components/textArea'
import KanseiTextField from 'components/textField'
import KanseiTimePicker from 'components/timePicker'
import { securityReportApi } from 'hooks/api/securityReport'
import { NewDate } from 'hooks/helper/date'
import { BUSINESS_DIVISION_OPTIONS } from 'types/contract'
import { QUALIFICATION_OMISSION_OPTIONS } from 'types/security'
import { SecurityReport, SecurityReportResult } from 'types/securityReport'
import { PRIMARY_COLOR } from 'valiable'

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

interface Form {
  id: string
  responsiblePersonSignature: string
  note: string
  securityReportResults: {
    id: string
    securityName: string
    qualifications: number[]
    securityId: string
    startTime: Dayjs
    endTime: Dayjs
    overTime: number
    restTime: number
  }[]
}

export default function SecurityReportPage(): JSX.Element {
  const query = useQuery()
  const detailId = query.get('contract_detail_id') || ''
  // FIXME 取得できなくても当日になる。バグの温床になるので修正したい
  const date = NewDate(query.get('date'))
  const [report, setReport] = useState<SecurityReport>()
  const [open, setOpen] = useState<boolean>(false)
  const [openSuccess, setOpenopenSuccess] = useState<boolean>(false)
  const { register, handleSubmit, control, reset, getValues } = useForm<Form>({
    defaultValues: {
      securityReportResults: [],
    },
  })

  const handleClose = () => {
    setOpen(false)
  }

  const returnToTop = () => {
    location.href = '/assign/?slide=close'
  }
  const fetchData = useCallback(() => {
    securityReportApi()
      .findByDetailIdAndDate(detailId, date)
      .then((res) => {
        setReport(res.data)
        reset({
          id: res.data.id,
          responsiblePersonSignature:
            res.data.responsiblePersonSignature.trim(),
          note: res.data.note,
          securityReportResults: res.data.securityReportResults?.map(
            (result) => ({
              id: result.id,
              securityName: `${result.security?.lastName || ''} ${result.security?.firstName || ''}`,
              qualifications: result.security?.qualifications,
              securityId: result.securityId,
              startTime: result.startTime,
              endTime: result.endTime,
              restTime: result.restTime,
              overTime: result.overTime,
            }),
          ),
        })
      })
  }, [reset])

  const qualificationLabel = (qs: number[]) => {
    if (!report) return null

    if (report?.qualifications?.length === 0) {
      return <>無</>
    }
    return (
      <>
        {report?.qualifications?.map((qualification) => {
          const isChecked = qs.includes(qualification)
          return (
            <span key={qualification}>
              <label
                style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
              >
                <input type="checkbox" checked={isChecked} readOnly />
                {
                  QUALIFICATION_OMISSION_OPTIONS.find(
                    (x) => x.value === qualification,
                  )?.label
                }
              </label>
            </span>
          )
        })}
      </>
    )
  }

  const signed = !!report?.responsiblePersonSignature

  const sign = async (form: Form): Promise<void> => {
    if (!report) {
      return
    }

    const signature = getValues('responsiblePersonSignature').trim()
    if (signature === '') {
      return alert('案件の終了には責任者のサインが必須です')
    }
    const res: SecurityReportResult[] = form.securityReportResults.map((x) => {
      return {
        id: x.id,
        securityId: x.securityId,
        securityReportId: form.id,
        startTime: x.startTime,
        endTime: x.endTime,
        restTime: x.restTime,
        overTime: x.overTime,
      }
    })
    securityReportApi()
      .sign(form.id, form.responsiblePersonSignature, form.note, res)
      .then(() => {
        setOpenopenSuccess(true)
      })
  }

  const { fields } = useFieldArray({
    control,
    name: 'securityReportResults',
  })

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <Box>
      {signed && (
        <Alert
          severity="success"
          sx={{
            margin: '10px auto 0',
            width: '80%',
          }}
        >
          サイン済み
        </Alert>
      )}
      <Box sx={{ marginBottom: '50px' }}>
        <Box
          sx={{
            borderBottom: `solid 2px ${PRIMARY_COLOR}`,
            margin: '10px 20px 0',
            fontWeight: 'bold',
          }}
        >
          警備報告書
        </Box>
        <Box component="form" onSubmit={handleSubmit(sign)}>
          <Grid
            container
            rowGap={2}
            sx={{ marginTop: '20px', padding: '0 20px' }}
          >
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: 'bold' }}>
                {report?.date.format('YYYY年M月D日 (dd)')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={titleStyle}>ご契約先</Typography>
              <Typography>{report?.contractName}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={titleStyle}>現場住所</Typography>
              <Typography>{report?.address}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={titleStyle}>警備内容</Typography>
              <Typography>
                {getBusinessDivisionLabels(
                  report?.businessDivisions ?? [],
                ).join('、')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>

          <Grid
            container
            rowGap={2}
            sx={{ marginTop: '20px', padding: '0 20px' }}
          >
            <Grid item xs={12}>
              <h3 style={{ fontSize: '12px', color: 'grey' }}>警備員情報</h3>
            </Grid>
            {fields.map((field, index) => (
              <Box key={field.id}>
                <Divider
                  sx={{
                    marginBottom: '10px',
                    borderStyle: 'dotted',
                    borderColor: 'grey',
                    borderWidth: '1px',
                  }}
                />
                <Grid container rowGap={1.5}>
                  <Grid item xs={4}>
                    <Typography>No</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography sx={{ fontWeight: 'bold' }}>
                      {index + 1}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>名前</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography sx={{ fontWeight: 'bold' }}>
                      {field.securityName}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>必要資格有無</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography sx={{ fontWeight: 'bold' }}>
                      {qualificationLabel(field.qualifications)}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>開始時間</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <KanseiTimePicker
                      name={`securityReportResults.${index}.startTime`}
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>終了時間</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <KanseiTimePicker
                      name={`securityReportResults.${index}.endTime`}
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>休憩時間(時)</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <KanseiTextField
                      id={`${index}`}
                      register={register(
                        `securityReportResults.${index}.restTime`,
                        { valueAsNumber: true },
                      )}
                      type="decimal"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>残業時間(時)</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <KanseiTextField
                      id={`${index}`}
                      register={register(
                        `securityReportResults.${index}.overTime`,
                        { valueAsNumber: true },
                      )}
                      type="decimal"
                    />
                  </Grid>
                </Grid>
              </Box>
            ))}
            <Grid item xs={12}>
              <Typography>備考</Typography>
            </Grid>
            <Grid item xs={12}>
              <KumocanTextareaAutosize
                id="note"
                style={{
                  width: '100%',
                  height: '100px',
                  maxWidth: '100%',
                  borderColor: '#80808057',
                  borderRadius: '5px',
                }}
                register={register('note')}
              ></KumocanTextareaAutosize>
            </Grid>
            <Box sx={signStyle}>
              <Box sx={signContentStyle}>
                <Typography>責任者サイン</Typography>
                <KanseiTextField
                  id="responsiblePersonSignature"
                  register={register('responsiblePersonSignature')}
                  style={{ backgroundColor: 'white', marginTop: '10px' }}
                ></KanseiTextField>
                <KumocanButton
                  style={{
                    width: '100%',
                    height: '50px',
                    fontSize: '14px',
                    marginTop: '20px',
                  }}
                  disabled={signed}
                  onClick={() => setOpen(true)}
                >
                  登録
                </KumocanButton>
              </Box>
            </Box>
            <KumocanDialog open={openSuccess}>
              <Typography sx={{ textAlign: 'center', fontSize: '20px' }}>
                警備報告書にサインしました
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '20px',
                }}
              >
                <KumocanButton
                  style={{
                    width: '100px',
                    fontSize: '16px',
                  }}
                  onClick={returnToTop}
                >
                  戻る
                </KumocanButton>
              </Box>
            </KumocanDialog>{' '}
            <KumocanDialog open={open} handleClose={handleClose}>
              <Typography sx={{ marginBottom: '10px' }}>
                警備報告書を作成しますか？内容を確認ください
              </Typography>
              <span>
                責任者名:{' '}
                <strong>{getValues('responsiblePersonSignature')}</strong>
              </span>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '20px',
                }}
              >
                <KumocanButton
                  onClick={handleClose}
                  variant="outlined"
                  style={{ width: '100px', fontSize: '16px' }}
                >
                  いいえ
                </KumocanButton>
                <KumocanButton
                  buttonType="submit"
                  style={{ width: '100px', fontSize: '16px' }}
                  onClick={handleSubmit(sign)}
                >
                  はい
                </KumocanButton>
              </Box>
            </KumocanDialog>
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}

const signStyle: CSSProperties = {
  width: '100%',
  backgroundColor: '#8adad6',
  borderRadius: '4px',
  opacity: 0.8,
}

const signContentStyle: CSSProperties = {
  opacity: 1,
  width: '200px',
  margin: '20px auto 20px',
  textAlign: 'center',
}

const titleStyle: CSSProperties = {
  fontSize: '10px',
  color: 'grey',
}

// 業務区分のbit -> labelに
export const getBusinessDivisionLabels = (values: number[]): string[] => {
  const allChildren = BUSINESS_DIVISION_OPTIONS.flatMap(
    (group) => group.children,
  )

  return allChildren
    .filter((child) => values.includes(Number(child.value)))
    .map((child) => child.label)
}
