import {
  SentimentDissatisfiedRounded,
  SentimentNeutralRounded,
  SentimentVeryDissatisfiedRounded,
  SentimentVerySatisfiedRounded,
} from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { useState } from 'react'

import KumocanButton from 'components/button'
import KumocanDialog from 'components/dialog'
import KumocanTextareaAutosize from 'components/textArea'
import { assignApi } from 'hooks/api/assign'
import { SECURITY_REVIEW } from 'types/assign'
import { PRIMARY_COLOR } from 'valiable'

interface Props {
  id: string
  open: boolean
  setOpen: (v: boolean) => void
}

export default function Review(props: Props): JSX.Element {
  const [review, setReview] = useState<number | null>(null)
  const [step, setStep] = useState<1 | 2 | 3>(1)
  const [reason, setReason] = useState<string>('')
  const [isErr, setIsErr] = useState<boolean>(false)

  const handleSubmitReview = async () => {
    if (!review) {
      alert('満足度を選択してください！')
      return
    }
    try {
      await assignApi().updateSecurityReview(props.id, review)
      // 満足度が1,2の時は理由も聞く
      if (
        [
          SECURITY_REVIEW.CONSIDERED_QUITTING,
          SECURITY_REVIEW.UNPLEASANT,
        ].includes(review)
      ) {
        setStep(2)
      } else {
        setStep(3)
      }
    } catch {
      alert('満足度の送信に失敗しました。')
    }
  }

  const reverseStep = async () => {
    setStep(1)
  }

  const onInput = (v: string) => {
    const timed = v.trim()
    setReason(timed)
    if (timed === '') {
      setIsErr(true)
      return
    }
    setIsErr(false)
  }

  const handleSubmitReason = async () => {
    const trimed = reason.trim()
    if (!trimed) {
      setIsErr(true)
      return
    }
    assignApi()
      .updateSecurityReviewReasone(props.id, trimed)
      .finally(() => {
        setStep(3)
      })
  }

  const handleClose = () => {
    props.setOpen(false)
  }

  return (
    <KumocanDialog open={props.open} height="600px" minWidth="95vw">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Typography
          sx={{
            width: '100%',
            fontSize: '23px',
            fontWeight: 'bold',
            color: PRIMARY_COLOR,
            paddingBottom: '6px',
            borderBottom: '1px solid',
          }}
        >
          勤務満足度調査
        </Typography>
        <Typography sx={{ fontSize: '14px' }}>
          {step === 2 ? (
            <>
              ※回答はくもかんを運営する(株)straya以外には
              <br />
              共有されませんので、ご安心ください。
              <br />
              管制の方へのご連絡は直接お願いいたします。
            </>
          ) : (
            <>
              ※現場のお客様や他の警備員には
              <br />
              一切共有されませんのでご安心ください
            </>
          )}
        </Typography>

        {step === 1 ? (
          // Step 1: 勤務満足度調査
          <>
            <Typography
              sx={{
                marginTop: '8px',
                fontSize: '22px',
                fontWeight: 'bold',
                whiteSpace: 'nowrap',
              }}
            >
              Q.今日の勤務はいかがでしたか？
            </Typography>
            {SECURITY_REVIEW_OPTIONS.map((option) => (
              <KumocanButton
                key={option.value}
                onClick={() => setReview(option.value)}
                variant={review === option.value ? 'contained' : 'outlined'}
                style={{
                  width: '100%',
                  height: '65px',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  paddingLeft: '8px',
                  color: '#4B4B4B',
                }}
              >
                {option.label}
              </KumocanButton>
            ))}
            <KumocanButton
              onClick={handleSubmitReview}
              variant="contained"
              style={{
                backgroundColor: review ? '#14b7ae' : '#e0e0e0',
                color: review ? '#ffffff' : '#757575',
                minWidth: '100px',
                height: '60px',
                fontSize: '20px',
                marginTop: '10px',
                width: '100%',
                fontWeight: 'bold',
              }}
              disabled={!review}
            >
              次へ
            </KumocanButton>
          </>
        ) : step === 2 ? (
          // Step 2: 選択肢を出しながら理由を聞く
          <>
            <Box
              sx={{
                padding: '8px',
                borderRadius: '8px',
                width: '95%',
                backgroundColor: PRIMARY_COLOR,
                color: 'white',
              }}
            >
              {
                SECURITY_REVIEW_OPTIONS.find(
                  (option) => option.value === review,
                )?.label
              }
            </Box>
            <Typography sx={{ fontSize: '22px', fontWeight: 'bold' }}>
              Q.その理由を教えてください。
            </Typography>
            <KumocanTextareaAutosize
              id="reason"
              onInput={onInput}
              placeholder={'ここに記載してください'}
              style={{
                width: '100%',
                minHeight: '100px',
                borderRadius: '8px',
                border: '1px solid #ccc',
                padding: '8px',
                fontSize: '16px',
              }}
            />
            {isErr && (
              <Typography
                sx={{
                  color: 'red',
                  fontSize: '18px',
                  textAlign: 'left',
                  width: '100%',
                }}
              >
                理由を入力してください
              </Typography>
            )}
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                marginTop: '10px',
                width: '100%',
              }}
            >
              <KumocanButton
                onClick={reverseStep}
                variant="contained"
                color="inherit"
                style={{
                  height: '60px',
                  fontSize: '18px',
                  width: '100%',
                  fontWeight: 'bold',
                  color: 'white',
                }}
              >
                戻る
              </KumocanButton>
              <KumocanButton
                onClick={handleSubmitReason}
                variant="contained"
                style={{
                  height: '60px',
                  fontSize: '20px',
                  width: '100%',
                  fontWeight: 'bold',
                }}
              >
                送信する
              </KumocanButton>
            </Box>
          </>
        ) : (
          // Step 3: Thanksモーダル
          <>
            <Typography
              sx={{ marginTop: '8px', fontSize: '20px', fontWeight: 'bold' }}
            >
              ご協力ありがとうございました
            </Typography>
            <img
              src="/images/thanks.png"
              alt="Thanks"
              style={{
                maxWidth: '70%',
                height: 'auto',
              }}
            />
            <KumocanButton
              onClick={handleClose}
              variant="contained"
              color="inherit"
              style={{
                height: '60px',
                fontSize: '20px',
                marginTop: '10px',
                width: '100%',
              }}
            >
              閉じる
            </KumocanButton>
          </>
        )}
      </Box>
    </KumocanDialog>
  )
}

export const SECURITY_REVIEW_OPTIONS = [
  {
    label: (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <SentimentVeryDissatisfiedRounded
          sx={{ color: '#FF005A', fontSize: '40px' }}
        />
        <Typography
          sx={{ textAlign: 'left', fontSize: '20px', fontWeight: 'bold' }}
        >
          会社を辞めたくなるほど
          <br />
          嫌な勤務だった
        </Typography>
      </Box>
    ),
    value: SECURITY_REVIEW.CONSIDERED_QUITTING,
  },
  {
    label: (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <SentimentDissatisfiedRounded
          sx={{ color: '#CF46A8', fontSize: '40px' }}
        />
        <Typography
          sx={{ textAlign: 'left', fontSize: '20px', fontWeight: 'bold' }}
        >
          嫌な勤務だった
        </Typography>
      </Box>
    ),
    value: SECURITY_REVIEW.UNPLEASANT,
  },
  {
    label: (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <SentimentNeutralRounded sx={{ color: '#6B7ACE', fontSize: '40px' }} />

        <Typography
          sx={{ textAlign: 'left', fontSize: '20px', fontWeight: 'bold' }}
        >
          過去にもっと
          <br />
          良い勤務があった
        </Typography>
      </Box>
    ),
    value: SECURITY_REVIEW.HAD_BETTER_BEFORE,
  },
  {
    label: (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <SentimentVerySatisfiedRounded
          sx={{ color: '#14AAD3', fontSize: '40px' }}
        />

        <Typography
          sx={{ textAlign: 'left', fontSize: '20px', fontWeight: 'bold' }}
        >
          この勤務を続けたい
        </Typography>
      </Box>
    ),
    value: SECURITY_REVIEW.WILLING_TO_CONTINUE,
  },
]
