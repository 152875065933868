import axios, { AxiosInstance } from 'axios'
import Cookies from 'js-cookie'
import { stringify } from 'qs'

const SESSION_KEY = 'KANSEI-APP-SESSION'

export function _axios(): AxiosInstance {
  const api: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_HOST,
    paramsSerializer: (params) => {
      return stringify(params, { arrayFormat: 'repeat' })
    },
  })

  api.interceptors.request.use((request) => {
    const sessionKey = Cookies.get(SESSION_KEY)
    if (sessionKey) {
      request.headers.Authorization = sessionKey
    }
    return request
  })

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      const response = error.response
      if (response.status === 401) {
        // cookieのsessionを削除してmiddlewareで弾くように
        Cookies.remove(SESSION_KEY)
      }
      throw response
    },
  )
  return api
}

export function AddAuthSession(token: string) {
  Cookies.set(SESSION_KEY, token)
}

export function GetAuthSession(): string | undefined {
  const token = Cookies.get(SESSION_KEY)
  return token
}
