import {
  LocationOn,
  StorefrontRounded,
  Summarize,
  WcRounded,
} from '@mui/icons-material'
import { Box, Typography, alpha } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useRef, useState } from 'react'

import KumocanButton from 'components/button'
import KumocanDialog from 'components/dialog'
import KumocanSimpleTextareaAutosize from 'components/simpleTextArea'
import WvButton from 'components/wvButton'
import { assignApi } from 'hooks/api/assign'
import { securityApi } from 'hooks/api/security'
import { ASSIGN_STATUS, AssignWithContract } from 'types/assign'
import { PRIMARY_COLOR } from 'valiable'

import Review from './components/review'

export default function AssignPage(): JSX.Element {
  enum MapType {
    NORMAL,
    WC,
    CONVENIENCE,
  }
  const [assign, setAssign] = useState<AssignWithContract>()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [isReviewDialogOpen, setIsReviewDialogOpen] = useState<boolean>(false)

  const today = dayjs().format('YYYY-MM-DD') // 表示用
  const scleentoday = dayjs().format('YYYY/MM/DD (dd)')
  const [currentTime, setCurrentTime] = useState(dayjs().format('HH:mm'))
  const fetchData = async () => {
    try {
      const assignResponse = await securityApi().filterAssignByDate(today)
      setAssign(assignResponse.data)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  useEffect(() => {
    fetchData()

    const timer = setInterval(() => {
      setCurrentTime(dayjs().format('HH:mm')) // 毎分現在の時間を更新
    }, 60000)

    return () => clearInterval(timer)
  }, [today])

  const getNextStatus = (currentStatus: number) => {
    switch (currentStatus) {
      case ASSIGN_STATUS.AVAILABLE_FOR_WORK:
        return ASSIGN_STATUS.ATTENDANCE_REPORT
      case ASSIGN_STATUS.ATTENDANCE_REPORT:
        return ASSIGN_STATUS.ATTENDANCE_UP
      case ASSIGN_STATUS.ATTENDANCE_UP:
        return ASSIGN_STATUS.ATTENDANCE_DOWN
      case ASSIGN_STATUS.ATTENDANCE_DOWN:
        return ASSIGN_STATUS.ATTENDANCE_DOWN
      default:
        return ASSIGN_STATUS.COMPLETED
    }
  }

  const handleButtonClick = () => {
    handleOpen()
  }

  const submit = () => {
    if (assign) {
      setIsSubmitting(true)
      const nextStatus = getNextStatus(assign.status)
      assignApi()
        .updateStatus(assign.id, nextStatus)
        .then(() => {
          setAssign({ ...assign, status: nextStatus })
          setIsDialogOpen(false)
          if (nextStatus === ASSIGN_STATUS.ATTENDANCE_DOWN) {
            setIsReviewDialogOpen(true)
          }
        })
        .finally(() => {
          setIsSubmitting(false)
        })
    }
  }

  const throttledInputHandler = useRef(
    throttle((id: string, value: string) => {
      assignApi().updateSecurityNote(id, value)
    }, 2000),
  )

  const handleInput = (value: string) => {
    const trimed = value.trim()
    throttledInputHandler.current(assign?.id || '', trimed)
  }
  const handleOpen = () => {
    setIsDialogOpen(true)
  }
  const handleClose = () => {
    setIsDialogOpen(false)
    setIsReviewDialogOpen(false)
  }

  const toSecurityReport = () => {
    location.href = `/security-report?contract_detail_id=${assign?.contractDetail.id}&slide=right`
  }

  const googleMapLink = (type: MapType) => {
    if (!assign) return ''
    const { lat, lng, address } = assign
    let query = ''
    let specificLink = false

    switch (type) {
      case MapType.WC:
        query = 'トイレ'
        specificLink = true
        break
      case MapType.CONVENIENCE:
        query = 'コンビニ'
        specificLink = true
        break
      case MapType.NORMAL:
      default:
        query = ''
        break
    }

    if (lat && lng && lat !== 0 && lng !== 0) {
      if (specificLink) {
        location.href = `https://www.google.com/maps/search/${query}/@${lat},${lng},17z?launch_app=true`
      } else {
        location.href = `https://www.google.com/maps/search/?api=1&query=${query} near ${lat},${lng}&launch_app=true`
      }
    } else if (address) {
      location.href = `https://www.google.com/maps/search/?api=1&query=${query} near ${encodeURIComponent(address)}&launch_app=true`
    } else {
      location.href = 'https://www.google.com/maps?launch_app=true'
    }
  }
  return (
    <Box
      sx={{
        width: '95%',
        margin: '10px auto 100px', // センター揃え
        overflowX: 'hidden', // 横スクロール禁止
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Box
        sx={{
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 3,
          padding: '20px 0',
          borderRadius: '8px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            component={'span'}
            sx={{
              fontSize: '20px',
              fontWeight: 'bold',
              color: '#333',
            }}
          >
            {scleentoday}
          </Typography>
          <Typography
            component={'span'}
            sx={{
              fontSize: '84px',
              fontWeight: 'bold',
              color: '#333',
              letterSpacing: '0.05em',
            }}
          >
            {currentTime}
          </Typography>
        </Box>

        {!assign?.id ? (
          <Box
            sx={{
              backgroundColor: '#ffffff',
              padding: '20px 20px',
              borderRadius: '12px',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              textAlign: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: '24px',
                fontWeight: 'bold',
                color: '#757575',
              }}
            >
              案件なし
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                color: '#999999',
                marginTop: '8px',
              }}
            >
              現在、割り当てられた案件はありません。
            </Typography>
          </Box>
        ) : (
          <>
            <WvButton
              type="secondary"
              style={{
                width: '100%',
                fontSize: '64px',
                fontWeight: 'bold',
                letterSpacing: '0.2em',
                borderRadius: 10,
              }}
              onClick={handleButtonClick}
              status={assign?.status}
            ></WvButton>
            <Box
              sx={{
                background: alpha(PRIMARY_COLOR, 0.1),
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: 1,
                padding: '10px 0',
                marginTop: '20px',
              }}
            >
              <Typography
                component={'span'}
                sx={{
                  fontSize: '18px',
                  color: '#757575',
                }}
              >
                {assign?.contractDetail.name}
              </Typography>
              <Typography
                component={'span'}
                sx={{
                  fontSize: '18px',
                  color: '#757575',
                }}
              >
                {dayjs(assign?.contractDetail.startTime).format('HH:mm')}~
                {dayjs(assign?.contractDetail.endTime).format('HH:mm')}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <WvButton
                type="info"
                style={{
                  width: '100%',
                  height: '60px',
                  marginTop: '20px',
                }}
                onClick={() => googleMapLink(MapType.NORMAL)}
              >
                <LocationOn />
                <span
                  style={{
                    fontSize: 18,
                  }}
                >
                  現場の地図
                </span>
              </WvButton>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '20px',
              }}
            >
              {/* コンビニやトイレのボタン */}
              <WvButton
                type="secondary"
                style={{
                  width: '48%',
                  height: '60px',
                }}
                onClick={() => googleMapLink(MapType.WC)}
              >
                <WcRounded />
                <span
                  style={{
                    fontSize: 18,
                  }}
                >
                  トイレ
                </span>
              </WvButton>
              <WvButton
                type="secondary"
                style={{
                  width: '48%',
                  height: '60px',
                }}
                onClick={() => googleMapLink(MapType.CONVENIENCE)}
              >
                <StorefrontRounded />
                <span
                  style={{
                    fontSize: 18,
                  }}
                >
                  コンビニ
                </span>
              </WvButton>
            </Box>
            {assign?.isLeader === true && (
              <WvButton
                type="securityReport"
                style={{
                  width: '100%',
                  height: '60px',
                  marginTop: '20px',
                  fontSize: '20px',
                  fontWeight: 'bold',
                }}
                onClick={toSecurityReport}
              >
                <Summarize />
                警備報告書
              </WvButton>
            )}
            <KumocanSimpleTextareaAutosize
              id="text-area"
              onInput={handleInput}
              placeHolder="警備員用メモ"
              defaultValue={assign?.securityNote}
              style={{
                width: '96%',
                height: '100px',
                fontSize: '20px',
                padding: '5px',
                marginTop: '20px',
              }}
            />
            <KumocanDialog open={isDialogOpen} handleClose={handleClose}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                  gap: 5,
                }}
              >
                <Typography
                  sx={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  更新します。よろしいですか？
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <KumocanButton
                    onClick={handleClose}
                    variant="outlined"
                    style={{
                      minWidth: '100px',
                      height: '50px',
                      fontSize: '18px',
                    }}
                  >
                    キャンセル
                  </KumocanButton>
                  <KumocanButton
                    disabled={isSubmitting}
                    onClick={submit}
                    style={{
                      minWidth: '100px',
                      height: '50px',
                      fontSize: '18px',
                    }}
                  >
                    OK
                  </KumocanButton>
                </Box>
              </Box>
            </KumocanDialog>
            <Review
              id={assign.id}
              open={isReviewDialogOpen}
              setOpen={setIsReviewDialogOpen}
            />
          </>
        )}
      </Box>
    </Box>
  )
}

function throttle(func: (...args: any[]) => void, limit: number) {
  let lastFunc: NodeJS.Timeout
  let lastRan: number

  return function (...args: any[]) {
    if (!lastRan) {
      func(...args)
      lastRan = Date.now()
    } else {
      clearTimeout(lastFunc)
      lastFunc = setTimeout(
        function () {
          if (Date.now() - lastRan >= limit) {
            func(...args)
            lastRan = Date.now()
          }
        },
        limit - (Date.now() - lastRan),
      )
    }
  }
}
