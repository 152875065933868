import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { MenuItem, Select } from '@mui/material'
import { CSSProperties } from 'react'
import { Control, Controller, FieldErrors } from 'react-hook-form'

export interface Option {
  label: string
  value: string | number
}

interface Props {
  name: string
  options: Option[]
  control: Control<any>
  style?: CSSProperties
  disabled?: boolean
  multiple?: boolean
  required?: boolean
  onBlur?: () => void
  errors?: FieldErrors
  onChange?: (e: any) => void
}

export default function KumocanSelectBox(props: Props) {
  const {
    options,
    name,
    control,
    style,
    disabled = false,
    multiple = false,
    required = false,
    onBlur,
    onChange,
  } = props

  const defaultStyle: CSSProperties = {
    minWidth: '100%',
    maxWidth: 'inherit',
    display: 'inline-block',
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={control._defaultValues}
      rules={{ required: required ? 'このフィールドは必須です' : false }}
      render={({ field }) => (
        <Select
          required={required}
          {...field}
          sx={style ? style : defaultStyle}
          onBlur={onBlur}
          multiple={multiple}
          disabled={disabled}
          IconComponent={KeyboardArrowDownIcon}
          onChange={(e) => {
            field.onChange(e)
            if (onChange) onChange(e)
          }}
        >
          {options.map((option) => {
            return (
              <MenuItem
                key={option.value}
                value={option.value}
                sx={{ fontSize: '20px' }}
              >
                {option.label}
              </MenuItem>
            )
          })}
        </Select>
      )}
    />
  )
}
