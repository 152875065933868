import { Dayjs } from 'dayjs'

import { Client } from './client'
import { Option } from './input'

export interface ContractDetail {
  id: string
  contractId: string
  contractBillingId: string
  name: string
  startDate: Dayjs
  endDate: Dayjs
  postalCode: string
  address: string
  teamName: string
  startTime: Dayjs
  endTime: Dayjs
  numberOfSecurities: number
  businessDivision: number
  contract?: Contract
  contractBilling?: ContractBilling
  nearestStation?: string
}

export interface ContractBilling {
  id: string
  title: string
  contractId: string
  shiftType: number
  price: number
  qualifications: number[]
  overTimePrice: number
  underTimePrice: number
}

export interface Contract {
  id: string
  companyBranchId: string
  name: string
  clientId: string
  phoneNumber: string
  faxNumber: string
  contractedAt: Dayjs
  madeAt: Dayjs
  contractDetails: ContractDetail[]
  contractBillings: ContractBilling[]
  client?: Client
  status: number
}

export type ParentOption = {
  label: string
  value: number
  children: Option[]
}

export const BUSINESS_DIVISION = {
  // 施設警備グループ
  FACILITY: 1 << 0, // 1 施設
  PATROL: 1 << 1, // 2 巡回
  SECURITY: 1 << 2, // 4 保安

  // 交通誘導警備グループ
  TRAFFIC_GUIDANCE: 1 << 3, // 8 交通誘導
  ROAD_CONSTRUCTION: 1 << 4, // 16 道路工事
  BUILDING_SITE: 1 << 5, // 32 建設現場
  WATER_CONSTRUCTION: 1 << 6, // 64 水道工事
  ELECTRICAL_CONSTRUCTION: 1 << 7, // 128 電気工事
  HIGHWAY_REGULATION: 1 << 8, // 256 高速規制
  STREET_REGULATION: 1 << 9, // 512 街路規制
  HIGH_VEHICLE_MONITORING: 1 << 10, // 1024 高車監視

  // 雑踏警備グループ
  CROWD: 1 << 11, // 2048 雑踏
  TRANSPORT: 1 << 12, // 4096 運搬

  // 身辺警備グループ
  PERSONAL: 1 << 13, // 8192 身辺

  // 鉄道関連警備グループ
  TRAIN_SURVEILLANCE: 1 << 14, // 16384 列車見張
}

export const ALL_BUSINESS_DIVISION = [...Object.values(BUSINESS_DIVISION)]

export const BUSINESS_DIVISION_OPTIONS: ParentOption[] = [
  {
    label: '施設警備',
    value: 1,
    children: [
      { label: '施設', value: BUSINESS_DIVISION.FACILITY },
      { label: '巡回', value: BUSINESS_DIVISION.PATROL },
      { label: '保安', value: BUSINESS_DIVISION.SECURITY },
    ],
  },
  {
    label: '交通誘導警備',
    value: 2,
    children: [
      {
        label: '交通誘導',
        value: BUSINESS_DIVISION.TRAFFIC_GUIDANCE,
      },
      {
        label: '道路工事',
        value: BUSINESS_DIVISION.ROAD_CONSTRUCTION,
      },
      {
        label: '建築現場',
        value: BUSINESS_DIVISION.BUILDING_SITE,
      },
      {
        label: '水道工事',
        value: BUSINESS_DIVISION.WATER_CONSTRUCTION,
      },
      {
        label: '電気工事',
        value: BUSINESS_DIVISION.ELECTRICAL_CONSTRUCTION,
      },
      {
        label: '高速規制',
        value: BUSINESS_DIVISION.HIGHWAY_REGULATION,
      },
      {
        label: '街路規制',
        value: BUSINESS_DIVISION.STREET_REGULATION,
      },
      {
        label: '高車監視',
        value: BUSINESS_DIVISION.HIGH_VEHICLE_MONITORING,
      },
    ],
  },
  {
    label: '雑踏警備',
    value: 3,
    children: [
      { label: '雑踏', value: BUSINESS_DIVISION.CROWD },
      { label: '運搬', value: BUSINESS_DIVISION.TRANSPORT },
    ],
  },
  {
    label: '身辺警備',
    value: 4,
    children: [{ label: '身辺', value: BUSINESS_DIVISION.PERSONAL }],
  },
  {
    label: '鉄道関連警備',
    value: 5,
    children: [
      { label: '列車見張', value: BUSINESS_DIVISION.TRAIN_SURVEILLANCE },
    ],
  },
]
