import { Dayjs } from 'dayjs'
import { CSSProperties } from 'react'

export interface Notification {
  id: string
  title: string
  content: string
  shiftType: number
  contentType: number
  status: number
  sentAt: Dayjs
}

export const notificationStatus = {
  Notifying: 10, // 通知中
  NotifyFailure: 20, // 通知失敗
  Notified: 30, // 通知済み
  AlreadyRead: 40, // 既読
}

export const contentType = {
  AssignConfirmation: 1, // 配置の可否確認
  AssignSuspension: 2, // 配置の中止案内
  Announcement: 20, // 社内報
  ThoughtfulInterviewsVER_1: 100, // システム通知[警備員の思考性アンケートver1]
}

export const BADGE_TYPE = {
  DAY: 1, // 日勤
  NIGHT: 2, // 夜勤
  CROWD: 3, // 雑踏
}

// バッジタイプのオプション
export const BADGE_TYPE_OPTIONS: { label: string; value: number }[] = [
  {
    label: '日勤',
    value: BADGE_TYPE.DAY,
  },
  {
    label: '夜勤',
    value: BADGE_TYPE.NIGHT,
  },
  {
    label: '雑踏',
    value: BADGE_TYPE.CROWD,
  },
]

// バッジタイプのラベル取得関数
export const getBadgeLabel = (badgeType: number): string => {
  const option = BADGE_TYPE_OPTIONS.find((option) => option.value === badgeType)
  return option ? option.label : '不明'
}

// バッジタイプのスタイル取得関数
export const getBadgeStyle = (badgeType: number): CSSProperties => {
  switch (badgeType) {
    case BADGE_TYPE.DAY:
      return { backgroundColor: '#FF4800', color: 'white' } // 日勤
    case BADGE_TYPE.NIGHT:
      return { backgroundColor: 'rgb(0, 98, 140)', color: 'white' } // 夜勤
    case BADGE_TYPE.CROWD:
      return { backgroundColor: '#32a852', color: 'white' } // 雑踏
    default:
      return { backgroundColor: 'gray', color: 'white' } // 不明なタイプ
  }
}
