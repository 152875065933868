import { AxiosResponse } from 'axios'

import { NewDate } from 'hooks/helper/date'
import { Notification } from 'types/notification'
import { FindAssignByIdRes, OrderWithAssign } from 'types/order'

import { _axios } from '../axios'

const GET = `/notifications`
const FIND_BY_ID = (id: string) => `/notifications/${id}`
const FIND_ASSIGN_BY_ID = (id: string) => `/notifications/${id}/assign`
const FIND_INTERVIEW = (id: string) => `/notifications/${id}/interview`
const UPDATE_INTERVIEW = (id: string) => `/notifications/${id}/interview`
const FIND_ORDER_BY_ID = (id: string) => `/notifications/${id}/order`
const READED = (id: string) => `/notifications/${id}/read`
const REPLY_ASSIGN_POSSIBILITY = (id: string) =>
  `/notifications/${id}/assign-response`
const CONFIRM_SUSPENTION = (id: string) =>
  `notifications/${id}/suspension-confirm`

export const notificationApi = () => {
  const getAll = async (
    isUnRead: boolean,
  ): Promise<AxiosResponse<Notification[]>> => {
    const params = {
      isUnRead: isUnRead,
    }
    return await _axios()
      .get(GET, { params })
      .then((res) => {
        res.data = res.data.notifications.map((x: Notification) => ({
          ...x,
          sentAt: NewDate(x.sentAt),
        }))
        return res
      })
  }

  const findById = async (id: string): Promise<AxiosResponse<Notification>> => {
    return await _axios()
      .get(FIND_BY_ID(id))
      .then((res) => {
        res.data = res.data.notification
        res.data.sentAt = NewDate(res.data.sentAt)
        return res
      })
  }

  const findAssignById = async (
    id: string,
  ): Promise<AxiosResponse<FindAssignByIdRes>> => {
    return await _axios()
      .get(FIND_ASSIGN_BY_ID(id))
      .then((res) => {
        const order: OrderWithAssign = res.data.order
        order.date = NewDate(order.date)
        order.startTime = NewDate(order.startTime)
        order.endTime = NewDate(order.endTime)
        res.data = {
          volume: res.data.volume,
          qualifiedVolume: res.data.qualifiedVolume,
          nonQualifiedVolume: res.data.nonQualifiedVolume,
          order: order,
          replyFomat: res.data.replyFomat,
          otherAssigns: res.data.otherAssigns,
          otherAllianceAssigns: res.data.otherAllianceAssigns,
        }
        return res
      })
  }

  const findInteview = async (
    id: string,
  ): Promise<
    AxiosResponse<{
      preferredWorkSites: number[]
      preferredTeamSizes: number[]
    }>
  > => {
    return await _axios()
      .get(FIND_INTERVIEW(id))
      .then((res) => {
        res.data = {
          preferredWorkSites: res.data.preferredWorkSites,
          preferredTeamSizes: res.data.preferredTeamSizes,
        }
        return res
      })
  }

  const updateInteview = async (
    id: string,
    preferredTeamSizes: number[],
    preferredWorkSites: number[],
  ): Promise<AxiosResponse<any>> => {
    const data = {
      preferredTeamSizes: preferredTeamSizes,
      preferredWorkSites: preferredWorkSites,
    }
    return await _axios()
      .put(UPDATE_INTERVIEW(id), data)
      .then((res) => {
        return res
      })
  }

  const findOrderById = async (
    id: string,
  ): Promise<AxiosResponse<OrderWithAssign>> => {
    return await _axios()
      .get(FIND_ORDER_BY_ID(id))
      .then((res) => {
        const order: OrderWithAssign = res.data.order
        order.date = NewDate(order.date)
        order.startTime = NewDate(order.startTime)
        order.endTime = NewDate(order.endTime)
        res.data = order
        return res
      })
  }

  const replyAssignPossibility = async (
    id: string,
    status: number,
    replyWhenAvailable: string,
    replyWhenUnavailable: string,
  ): Promise<AxiosResponse<any>> => {
    const params = {
      assignStatus: status,
      replyWhenAvailable: replyWhenAvailable,
      replyWhenUnavailable: replyWhenUnavailable,
    }
    return await _axios()
      .put(REPLY_ASSIGN_POSSIBILITY(id), params)
      .then((res) => {
        return res
      })
  }

  const suspensionConfirm = async (
    id: string,
    status: number,
  ): Promise<AxiosResponse<any>> => {
    const params = {
      SuspensionConfirm: status,
    }
    return await _axios()
      .put(CONFIRM_SUSPENTION(id), params)
      .then((res) => {
        return res
      })
  }

  const readed = async (id: string): Promise<AxiosResponse<any>> => {
    return await _axios()
      .patch(READED(id))
      .then((res) => {
        return res
      })
  }

  return {
    getAll,
    findById,
    findAssignById,
    findInteview,
    findOrderById,
    updateInteview,
    replyAssignPossibility,
    suspensionConfirm,
    readed,
  }
}
