import { Box, Typography, TextField, Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { securityApi } from 'hooks/api/security'
import { isDev } from 'hooks/helper/env'

export default function LoginPage() {
  const navigate = useNavigate()
  const [isLogined, setIsLogined] = useState<boolean>(false)
  const [id, setId] = useState('')
  const [password, setPassword] = useState('')

  useEffect(() => {
    const password = prompt('Enter password:')
    // localだと空だったらsecurity_company_branch_1_1でloginするように
    if (isDev && !password) {
      securityApi()
        .signIn('security_company_branch_1_1', 'password')
        .then(() => {
          location.href = '/assign'
        })
      return
    }
    securityApi()
      .authKumocan(password || '')
      .then(() => {
        setIsLogined(true)
      })
      .catch(() => {
        navigate('/top')
      })
  }, [navigate])

  const handleSubmit = () => {
    securityApi()
      .signIn(id, password)
      .then(() => {
        location.href = '/assign'
      })
  }

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: '#f5f5f5',
        gap: 2,
      }}
    >
      {isLogined ? (
        <>
          <Typography variant="h4" component="h1">
            Login
          </Typography>
          <TextField
            label="ID"
            variant="outlined"
            value={id}
            onChange={(e) => setId(e.target.value)}
            fullWidth
          />
          <TextField
            label="Password"
            variant="outlined"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
          />
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </>
      ) : (
        <Typography variant="h6" color="textSecondary">
          認証中...
        </Typography>
      )}
    </Box>
  )
}
