import linkifyHtml from 'linkify-html'

export const convertToLink = (text: string, isSlideRight: boolean = true) => {
  const options = {
    defaultProtocol: 'http',
    attributes: {
      style: 'color: #00f; text-decoration: underline;',
    },
    formatHref: (href: string) => {
      const url = new URL(href, 'http://straya.com')
      if (isSlideRight) {
        url.searchParams.append('slide', 'right')
      }
      return url.href.replace('http://straya.com', '')
    },
  }
  return linkifyHtml(text, options)
}
