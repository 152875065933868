import { AxiosResponse } from 'axios'
import dayjs, { Dayjs } from 'dayjs'

import { _axios } from 'hooks/axios'
import { Shift } from 'types/shift'
import { SecurityShiftResponse, ShiftManagement } from 'types/shiftManagement'

const FILTER_BY_DATE = `/shifts`
const FILTER_MANAGEMENT = `/shifts/managements`
const SUBMIT = `/shifts/managements/submit`
const SAVE = `/shifts/managements/save`

export const shiftApi = () => {
  const findByDate = async (
    start: Dayjs,
    end: Dayjs,
  ): Promise<AxiosResponse<Shift[]>> => {
    const params = {
      startDate: start.format('YYYY-MM-DD'),
      endDate: end.format('YYYY-MM-DD'),
    }
    return await _axios()
      .get(FILTER_BY_DATE, { params })
      .then((res) => {
        res.data = res.data.shifts
        return res
      })
  }

  const filterManagement = async (): Promise<
    AxiosResponse<ShiftManagement[]>
  > => {
    return await _axios()
      .get(FILTER_MANAGEMENT)
      .then((res) => {
        // API のレスポンス内の shiftManagements 配列から日付フィールドを dayjs に変換
        res.data = res.data.shiftManagements.map(
          (management: ShiftManagement) => ({
            ...management,
            fromDate: dayjs(management.fromDate),
            toDate: dayjs(management.toDate),
            scheduledRequestDate: dayjs(management.scheduledRequestDate),
            responses: management.responses.map(
              (response: SecurityShiftResponse) => ({
                ...response,
                date: dayjs(response.date),
              }),
            ),
          }),
        )
        return res
      })
  }

  const submit = async (
    shiftManagementId: string,
    values: {
      date: Dayjs
      schedule: number
    }[],
  ): Promise<AxiosResponse<Shift>> => {
    const params = {
      shiftManagementId: shiftManagementId,
      values: values.map((v) => ({
        date: v.date.format('YYYY-MM-DD'),
        schedule: v.schedule,
      })),
    }
    return await _axios()
      .post(SUBMIT, params)
      .then((res) => {
        res.data = res.data
        return res
      })
  }

  const save = async (
    shiftManagementId: string,
    date: Dayjs,
    schedule: number,
  ): Promise<AxiosResponse<any>> => {
    const params = {
      shiftManagementId: shiftManagementId,
      date: date.format('YYYY-MM-DD'),
      schedule: schedule,
    }
    return await _axios()
      .post(SAVE, params)
      .then((res) => {
        res.data = res.data
        return res
      })
  }

  return {
    findByDate,
    filterManagement,
    submit,
    save,
  }
}
