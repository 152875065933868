import { Schedule, Task } from '@kansei/ui'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { isSameMonth } from 'date-fns'
import React, { CSSProperties, useState, cloneElement } from 'react'

import { isToday, isFuture } from 'hooks/helper/date'

export type ListCalendarProps = {
  currentDate: Date
  schedules: Schedule[]
  onSelectTask: (schedule: Schedule, task: Task) => void
  changeDate: (date: Date) => void
}

const ListCalendar: React.FC<ListCalendarProps> = ({
  currentDate,
  schedules,
  onSelectTask,
  changeDate,
}) => {
  const [baseDate, setBaseDate] = useState(currentDate)

  // 日付を前後にスライドする関数
  const slideDate = (direction: 'prev' | 'next') => {
    const newDate = new Date(baseDate)
    if (direction === 'prev') {
      newDate.setDate(newDate.getDate() - 1)
    } else {
      newDate.setDate(newDate.getDate() + 1)
    }
    changeDate(newDate)
    setBaseDate(newDate)
  }

  // 4日分の日付配列を生成
  const getDates = () => {
    const dates = []
    const startDate = new Date(baseDate)
    startDate.setDate(startDate.getDate() - 1) // 前日から開始

    for (let i = 0; i < 4; i++) {
      const date = new Date(startDate)
      date.setDate(startDate.getDate() + i)
      dates.push(date)
    }
    return dates
  }

  // 曜日の取得（日本語）
  const getJapaneseWeekday = (date: Date) => {
    const weekdays = ['日', '月', '火', '水', '木', '金', '土']
    return weekdays[date.getDay()]
  }

  // タスクアイコンの生成
  const iconElement = (icon: React.ReactNode) => {
    return cloneElement(icon as React.ReactElement<any>, {
      style: {
        fontSize: '27px',
        color: 'rgba(255, 255, 255, 0.88)',
      },
    })
  }

  // タスクの生成
  const taskElement = (schedule: Schedule, task: Task, date: Date) => {
    return (
      <div
        style={taskStyle(isFuture(date), task)}
        onClick={() => onSelectTask(schedule, task)}
      >
        <div>
          {task.icon && iconElement(task.icon)}
          <p style={taskTimeTextStyle}>{task.time}</p>
          <p style={taskNameTextStyle}>{task.name}</p>
        </div>
      </div>
    )
  }

  return (
    <div style={containerStyle}>
      <div style={headerStyle}>
        {`${baseDate.getFullYear()}年 ${baseDate.getMonth() + 1}月`}
      </div>
      <div style={{ position: 'relative' }}>
        <div style={arrowUpBtnStyle}>
          <KeyboardArrowUpIcon
            style={arrowStyle}
            onClick={() => slideDate('prev')}
          />
        </div>

        <div style={arrowDownBtnStyle}>
          <KeyboardArrowDownIcon
            style={arrowStyle}
            onClick={() => slideDate('next')}
          />
        </div>

        <ul style={dateListStyle}>
          {getDates().map((date, index) => (
            <li key={String(date) + '-' + index} style={dateItemStyle}>
              <div style={dayLeftStyle}>
                <div style={todayStyle(isToday(date))}>
                  <p style={dayNumberStyle}>{date.getDate()}</p>
                  <p style={weekdayStyle}>({getJapaneseWeekday(date)})</p>
                </div>
              </div>

              <div style={dayRightStyle(date)}>
                {schedules.map((schedule, idx) => {
                  // 同日判定
                  if (
                    isSameMonth(schedule.date, date) &&
                    schedule.date.getDate() === date.getDate()
                  ) {
                    return (
                      <div
                        key={schedule.date.getDate() + '-' + idx}
                        style={schedulesStyle(date)}
                      >
                        {/*日勤*/}
                        {schedule.dayShiftTask
                          ? taskElement(schedule, schedule.dayShiftTask, date)
                          : null}
                        {/*夜勤*/}
                        {schedule.nightShiftTask
                          ? taskElement(schedule, schedule.nightShiftTask, date)
                          : null}
                        {/*日勤or夜勤*/}
                        {schedule.dayOrNightShiftTask
                          ? taskElement(
                              schedule,
                              schedule.dayOrNightShiftTask,
                              date,
                            )
                          : null}
                        {/*研修*/}
                        {schedule.trainingShiftTask
                          ? taskElement(
                              schedule,
                              schedule.trainingShiftTask,
                              date,
                            )
                          : null}
                        {/*日勤&夜勤*/}
                        {schedule.dayAndNightShiftTask
                          ? taskElement(
                              schedule,
                              schedule.dayAndNightShiftTask,
                              date,
                            )
                          : null}
                        {/*休日*/}
                        {schedule.reviewShiftTask
                          ? taskElement(
                              schedule,
                              schedule.reviewShiftTask,
                              date,
                            )
                          : null}
                      </div>
                    )
                  }
                  return null
                })}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

const arrowUpBtnStyle: React.CSSProperties = {
  position: 'absolute',
  top: '5px',
  left: '0',
  right: '0',
  textAlign: 'center',
  zIndex: 10,
}

const arrowDownBtnStyle: React.CSSProperties = {
  position: 'absolute',
  bottom: '5px',
  left: '0',
  right: '0',
  textAlign: 'center',
  zIndex: 10,
}

const containerStyle: React.CSSProperties = {
  width: '100%',
  maxWidth: '400px',
  overflow: 'hidden',
}

const headerStyle: React.CSSProperties = {
  padding: '12px',
  backgroundColor: 'white',
  textAlign: 'center',
  fontSize: '18px',
  fontWeight: 'bold',
}

const arrowStyle: React.CSSProperties = {
  cursor: 'pointer',
  color: '#6b7280',
  fontSize: '32px',
}

const dateListStyle: React.CSSProperties = {
  listStyle: 'none',
  margin: 0,
  padding: 0,
}

const dateItemStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  height: '120px',
  borderBottom: '2px dotted #e5e7eb',
}

const dayLeftStyle: React.CSSProperties = {
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '10%',
  height: '100%',
  textAlign: 'center',
  backgroundColor: '#f3f4f6',
}

const todayStyle = (isToday: boolean): CSSProperties => {
  return {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: isToday ? 'black' : '#f3f4f6',
    width: '32px',
    height: '32px',
    color: isToday ? 'white' : 'black',
  }
}

const dayNumberStyle: React.CSSProperties = {
  fontSize: '12px',
  fontWeight: 'bold',
}

const weekdayStyle: React.CSSProperties = {
  fontSize: '8px',
}

const dayRightStyle = (date: Date): React.CSSProperties => {
  const color = isFuture(date) ? 'white' : '#e5e7eb'
  return {
    height: '100%',
    width: '90%',
    backgroundColor: color,
  }
}

const schedulesStyle = (date: Date): CSSProperties => {
  const color = isFuture(date) ? 'white' : '#e5e7eb'
  return {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '4px',
    padding: '8px 16px',
    height: 'calc(100% - 16px)',
    width: '90%',
    backgroundColor: color,
    opacity: isFuture(date) ? 1 : 0.5,
  }
}

const taskStyle = (isFuture: boolean, task: Task): CSSProperties => {
  const color = isFuture ? task.color : '#d1d5db'
  const borderColor = task.status === 20 ? 'red' : color
  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    padding: '4px 4px 4px 16px',
    width: 'calc(100% - 26px)',
    height: 'calc(100% - 12px)',
    borderRadius: '4px',
    backgroundColor: color,
    border: `2px solid ${borderColor}`,
    color: 'white',
  }
}

const taskTimeTextStyle: React.CSSProperties = {
  fontSize: '16px',
  fontWeight: 'bold',
}

const taskNameTextStyle: React.CSSProperties = {
  fontSize: '10px',
}

export default ListCalendar
