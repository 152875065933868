import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import EditNoteIcon from '@mui/icons-material/EditNote'
import ListAltIcon from '@mui/icons-material/ListAlt'
import React, { CSSProperties } from 'react'

export enum Tab {
  LIST = 0,
  CALENDAR = 1,
  SUBMIT = 2,
}

export type Props = {
  activeTab: Tab
  onSelectTab: (tab: Tab) => void
}

const TabComponent: React.FC<Props> = ({ activeTab, onSelectTab }) => {
  const iconStyle: CSSProperties = {
    fontSize: '32px',
    fontWeight: 'bold',
  }

  const tabs = [
    { id: Tab.LIST, icon: <ListAltIcon style={iconStyle} />, label: '直近' },
    {
      id: Tab.CALENDAR,
      icon: <CalendarMonthIcon style={iconStyle} />,
      label: '月間',
    },
    { id: Tab.SUBMIT, icon: <EditNoteIcon style={iconStyle} />, label: '提出' },
  ]

  const containerStyle: CSSProperties = {
    display: 'flex',
    gap: '4px',
    padding: '0 8px',
    maxWidth: '400px',
    borderBottom: '2px solid #14b7ae',
  }

  const tabStyle = (isActive: boolean): CSSProperties => ({
    flex: 1,
    padding: '8px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '2px',
    fontWeight: 'bold',
    fontSize: '20px',
    transition: 'all 0.2s ease',
    cursor: 'pointer',
    border: 'none',
    backgroundColor: isActive ? '#14b7ae' : '#e5e7eb',
    color: isActive ? '#ffffff' : '#14b7ae',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  })

  const changeTab = (tabId: Tab) => {
    onSelectTab(tabId)
  }

  return (
    <div style={containerStyle}>
      {tabs.map((tab) => (
        <button
          key={tab.id}
          onClick={() => changeTab(tab.id)}
          style={tabStyle(activeTab === tab.id)}
        >
          {tab.icon}
          <span>{tab.label}</span>
        </button>
      ))}
    </div>
  )
}

export default TabComponent
